<template>
  <div id="app">
    <div v-if="!submitted">
    <Logovanje @updateNaslovna="submit" />
    </div>
    <div v-else>
      <h1>Електронски тестови</h1>
      <table class="table testovi" style="width:100%">
        <tr v-for="test in testovi" :key="test.ID">
           <td>

                <h3 class="naslov-testa">{{test.Naslov}}</h3>
                <div v-show="test.Autori != ''"><strong>Аутор(и)</strong>: <i>{{test.Autori}}</i></div>
                <div>Акредитовано: {{test.BrojLicence}} са {{test.BodovaZaDoktore}}</div>
                <div style="margin-bottom:20px">
                    Тест је акредитован до <strong>{{test.Zavrsava}}</strong> године
                </div>
                    <div style="margin-bottom:20px" v-html="test.Opis">
                        {{test.Opis}}
                    </div>
                    <div v-show="test.Literatura != ''">
                        <strong>Литература:</strong><br /><p v-html="test.Literatura">{{test.Literatura}}</p>
                    </div>
                
                    <div align="right" class="pitanja-navigacija" style="margin-top: 0.23em;padding-top: 0px !important;border-top: none;">
                      <div v-if="postojiKorisnikovTest(test.ID)">
                                <div v-if="getKorisnikovTest(test.ID).ZavrsenTest == '1' && getKorisnikovTest(test.ID).Prosao == '1'">
                                   <strong>
                                     <font color="green">
                                      Положен тест дана {{getKorisnikovTest(test.ID).TestZavrsio}} године.
                                      </font>
                                      </strong>
                                      <div  v-if="ispunioUslov(test.ID, test.BrojPokusajaKorisnika, test.BrojKorisnikovihPokusaja) == true">
                                      <div style= "display: inline-block;margin-right: 10px">Нисте задовољни?&nbsp;</div>
                                      <a v-bind:href="'zapocni_test.html?id=' + test.UniqueID">Поновите тест</a>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <a v-bind:href="'zapocni_test.html?id=' + test.UniqueID" v-if="getKorisnikovTest(test.ID).ZavrsenTest == '0'">Настави тест</a>
                                    <div v-else>
                                      <div  v-if="ispunioUslov(test.ID, test.BrojPokusajaKorisnika, test.BrojKorisnikovihPokusaja) == false">
                                         <strong> <font color="red" >Искористили сте све покушаје</font></strong>
                                      </div>
                                      <a v-bind:href="'zapocni_test.html?id=' + test.UniqueID" v-else>Започни тест</a>
                                    </div>
                                  </div>
                        </div>
                         <div v-else>
                                  <a v-bind:href="'zapocni_test.html?id=' + test.UniqueID">Започни тест</a>
                        </div>
                    </div>
            </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Logovanje from './components/Logovanje.vue';
import axios from 'axios';
import appConfig from './js/configuration.js';
import common from './js/common.js';
import $ from 'jquery';

export default {
  name: 'App',
  data: function(){
    return {
      testovi: [],
      korisnikoviTestovi: [],
      submitted: false,
      dummy: ''
    }
  },
  
  components: {
    Logovanje,
  },  
  computed: {
     
  },
   methods: {
        checkSession: function(){
        
          var _this = this;
          common.checkSession(appConfig).then(function(response){
              _this.submitted = response.data.result;
              //console.log(_this.submitted);
              if(_this.submitted) 
                _this.fetch();
              else
               $("#logovanje").show();
          });
          
      },
      fetch: function(){
         var _this = this;
         var postData = new FormData();
        var token = localStorage.getItem("userToken",);
          postData.append('token', token);                                       
          axios.post(appConfig.backendUrl + 'aktivniTestovi.php', postData).then(function(response){
              _this.testovi = response.data;
              if(_this.testovi.length>0) _this.fetchKorisnikoviTestovi();
          });
      },
      fetchKorisnikoviTestovi: function(){
         var _this = this;
          var postData = new FormData();
          var token = localStorage.getItem("userToken",);
          postData.append('token', token);
        axios.post(appConfig.backendUrl + 'korisnikoviTestovi.php', postData).then(function(response){
              _this.korisnikoviTestovi = response.data;
          });
      },
      postojiKorisnikovTest: function(id){
         var kt =  this.korisnikoviTestovi.filter(function(o){return o.OnlineTestID == id;})
          return (kt != null && kt.length > 0);
      },
     getKorisnikovTest: function(id){
          var kt =  this.korisnikoviTestovi.filter(function(o){return o.OnlineTestID == id;})
         return kt[0];
      },
      ispunioUslov: function(id, brojPokusaja, brojKorisnikovihPokusaja){
          var kt =  this.korisnikoviTestovi.filter(function(o){return o.OnlineTestID == id && o.ZavrsenTest != null;})
          console.log(id, brojKorisnikovihPokusaja, kt.length);
          if(brojKorisnikovihPokusaja > 0) {
            return brojKorisnikovihPokusaja >  kt.length;
          }
          return Number(brojPokusaja) > kt.length;

      },
        submit: function(){
          this.submitted = true;
          //console.log(this.submitted);
          if(this.submitted) this.fetch();
          //console.log('submit');
        }
    },
    mounted: function(){
      //console.log("mounted 1");
      this.checkSession();
      common.init();
       //$('[vp-cloak]').show();
    }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  /* color: #2c3e50;
  margin-top: 60px; */
}
</style>
